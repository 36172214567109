<template>
	<div>
		<Dialog :dialog="serviceDialog" :dialog-width="dialogWidth">
			<template v-slot:title>{{ type }} Service</template>
			<template v-slot:body>
				<v-form
					ref="serviceForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row v-if="serviceDialog" class="service-dialog">
						<!-- <v-col md="12" class="py-0">
							<RadioInput v-model="service.type" :disabled="pageLoading" :items="radioItems"></RadioInput>
						</v-col> -->
						<template>
							<v-col md="4" class="my-auto py-0">
								<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required">From</label>
							</v-col>
							<v-col md="8" class="py-0">
								<DateTimePicker
									hide-details
									required
									:min-date="today"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`service-from-${uniqFieldId}`"
									placeholder="From"
									v-model="service.from"
								></DateTimePicker>
							</v-col>
							<v-col md="4" class="my-auto py-0">
								<label :for="`service-to-${uniqFieldId}`" class="btx-label mt-3 required">To</label>
							</v-col>
							<v-col md="8" class="py-0">
								<DateTimePicker
									hide-details
									required
									:min-date="service.from"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`service-to-${uniqFieldId}`"
									placeholder="To"
									v-model="service.to"
								></DateTimePicker>
							</v-col>
							<v-col md="4" class="my-auto py-0">
								<label :for="`service-cost-${uniqFieldId}`" class="btx-label mt-3">Cost</label>
							</v-col>
							<v-col md="8" class="py-0">
								<PriceInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`service-cost-${uniqFieldId}`"
									placeholder="Cost"
									v-model="service.cost"
								></PriceInput>
							</v-col>
						</template>
						<!-- <template v-else>
							<v-col md="4" class="my-auto py-0">
								<label :for="`service-expected-completion-${uniqFieldId}`" class="btx-label mt-3 required"
									>Expected Completion</label
								>
							</v-col>
							<v-col md="8" class="py-0">
								<DateTimePicker
									hide-details
									:min-date="today"
									:required="service.indefinitely ? false : true"
									:disabled="pageLoading || (service.indefinitely ? true : false)"
									:loading="pageLoading"
									:id="`service-expected-completion-${uniqFieldId}`"
									placeholder="Expected Completion"
									v-model="service.expected_completion"
								></DateTimePicker>
							</v-col>
							<v-col md="4" class="py-0"> </v-col>
							<v-col md="8" class="py-0">
								<v-checkbox
									dense
									hide-details
									label="Under service Indefinitely"
									class="mt-3 mb-0 p-0"
									:true-value="1"
									:false-value="0"
									v-model="service.indefinitely"
								></v-checkbox>
							</v-col>
						</template> -->
						<v-col md="4" class="my-auto py-0">
							<label :for="`service-type-${uniqFieldId}`" class="btx-label mt-3 required"
								>Service Type</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<AutoCompleteInput
								hide-details
								:items="serviceTypeList"
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`service-type-${uniqFieldId}`"
								placeholder="Service Type"
								v-model="service.service_type"
								:rules="[vrules.required(service.service_type, 'Service Type')]"
								:class="{
									required: !service.service_type,
								}"
								v-on:click:append-outer="manageServiceTypeDialog = true"
								append-outer-icon="mdi-cog"
							></AutoCompleteInput>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
								>Service Performed by</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<RadioInput
								row
								hide-details
								v-model="service.performed_by"
								:disabled="pageLoading"
								:id="`service-performed-by-${uniqFieldId}`"
								:items="performedItems"
							></RadioInput>
						</v-col>
						<v-col md="4" class="my-auto py-0">
							<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
								>Service Location</label
							>
						</v-col>
						<v-col md="8" class="py-0">
							<RadioInput
								row
								hide-details
								v-model="service.location_by"
								:disabled="pageLoading"
								:id="`service-performed-by-${uniqFieldId}`"
								:items="performedPlaceItems"
							></RadioInput>
						</v-col>
						<!-- <v-col md="4" class="my-auto py-0">
							<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3">Resolve</label>
						</v-col>
						<v-col md="8" class="py-0">
							<v-checkbox dense color="blue" v-model="service.is_resolve" hide-details class="mt-0 mb-0">
							</v-checkbox>
						</v-col> -->
						<template v-if="service.performed_by == 1">
							<v-col md="4" class="my-auto py-0">
								<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-3">Supplier</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="vendorList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`vendor-${uniqFieldId}`"
									placeholder="Supplier"
									v-model="service.vendor"
								></AutoCompleteInput>
							</v-col>
						</template>
						<template v-else>
							<v-col md="4" class="my-auto py-0">
								<label :for="`member-${uniqFieldId}`" class="btx-label mt-3">Staff</label>
							</v-col>
							<v-col md="8" class="py-0">
								<AutoCompleteInput
									hide-details
									:items="memberList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`member-${uniqFieldId}`"
									placeholder="Staff"
									v-model="service.member"
								></AutoCompleteInput>
							</v-col>
						</template>
						<v-col md="4" class="py-0">
							<label :for="`description-${uniqFieldId}`" class="btx-label mt-3">Description</label>
						</v-col>
						<v-col md="8" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`description-${uniqFieldId}`"
								placeholder="Description"
								v-model="service.description"
							></TextAreaInput>
						</v-col>
						<!-- <v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
							Linked Perishables Items <br />
							<span class="font-level-0 text-muted">
								<v-icon small>mdi-progress-question</v-icon> The Perishables Items consumed during
								maintenance</span
							>
						</v-col>
						<v-col md="12" class="py-0">
							<v-layout>
								<v-flex md6>
									<AutoCompleteInput
										hide-details
										:items="inventoryList"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Perishables"
										v-model="inventory"
										v-on:change="selectInventory()"
										return-object
									></AutoCompleteInput>
								</v-flex>
								<v-flex md6> </v-flex>
							</v-layout>
							<div class="mt-3 overflow-y border-light-grey" style="max-height: calc(100vh - 337px)">
								<table width="100%" class="detail-table table-head-sticky">
									<thead>
										<tr>
											<th width="60%" class="p-2 light-blue-bg">Name</th>
											<th v-if="false" width="60%" class="p-2 light-blue-bg">Location</th>
											<th width="30%" class="p-2 light-blue-bg" colspan="2">Quantity</th>
										</tr>
									</thead>
									<tbody v-if="service.inventories.length">
										<tr v-for="(row, index) in service.inventories" :key="index">
											<td class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey">
												<p class="m-0 my-auto mt-3">{{ row.name }}</p>
											</td>
											<td v-if="false" class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
												<AutoCompleteInput
													hide-details
													:items="row.location_list"
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Location"
													v-model="row.location"
												></AutoCompleteInput>
											</td>
											<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
												<QuantityInput
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Quantity"
													v-model="row.quantity"
												></QuantityInput>
											</td>
											<td class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
												<v-btn
													color="red lighten-1"
													class="mt-3"
													v-on:click="removeInventory(index)"
													icon
													depressed
													small
												>
													<v-icon small>mdi-delete</v-icon>
												</v-btn>
											</td>
										</tr>
									</tbody>
									<tfoot v-else>
										<tr>
											<td colspan="3">
												<p class="m-0 row-not-found text-center py-3">
													<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
													Uhh... There are no linked inventory item at the moment.
												</p>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</v-col>
						<v-col md="12" class="font-level-3-bold pb-3 border-bottom-light-grey text-black mt-4 py-0">
							Attachments
						</v-col>
						<v-col md="12" class="py-0 mt-4">
										<template v-for="(data, index) in service.attachment">
											<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
												<v-col md="5" class="py-0">
													<v-file-input
													v-model="data.file"
													placeholder="Click here to select files"
													color="blue darken-4"
													multiple
													outlined
													class="mt-3"
													prepend-icon=""
													prepend-inner-icon="mdi-attachment"
													hide-details
													v-on:change="limitAttachment($event, index)"
													></v-file-input>
												</v-col>
												<v-col md="5" class="py-0">
													<TextInput
													hide-details
													placeholder="File Name"
													v-model="data.name"
													></TextInput>
												</v-col>
												<v-col md="1" class="py-0">
													<v-btn
														:disabled="service.attachment.length < 2"
														v-on:click="removeFile(index)"
														class="white--text mt-3"
														depressed
														color="red darken-4"
														tile
														style="margin-left: -10px;"
													
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</v-col>
												<v-col md="1" class="py-0">
													<v-btn
													:disabled="service.attachment.length > 2"
														v-on:click="addMore()"
														class="white--text mt-3"
														depressed
														color="blue darken-4"
														tile
														style="margin-left: -13px;"
													>
														<v-icon>mdi-plus</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</v-col> -->
						<!-- <v-col md="12" class="py-0 mt-4">
							<UploadFile v-on:files="manageFiles"> </UploadFile>
						</v-col> -->
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="serviceUuid"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onUpdateSubmit()"
				>
					Service Update
				</v-btn>
				<v-btn
					v-else
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Service
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</Dialog>
		<template v-if="manageServiceTypeDialog">
			<ManageServiceType
				:dialog="manageServiceTypeDialog"
				:serviceTypes="serviceTypeList"
				v-on:close-dialog="manageServiceTypeDialog = false"
				v-on:success="getServiceTypes"
			></ManageServiceType>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger, cloneDeep, findIndex } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
/* import { UploadFiles } from "@/core/lib/upload.lib"; */
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import RadioInput from "@/view/components/RadioInput";
//import QuantityInput from "@/view/components/QuantityInput";
import PriceInput from "@/view/components/PriceInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DateTimePicker from "@/view/components/DateTimePicker";
//import TextInput from "@/view/components/TextInput";

//import UploadFile from "@/view/components/UploadFile";
//import { GetLocationByInventory } from "@/core/lib/inventory.lib";
import { EventBus } from "@/core/event-bus/event.bus";
import ManageServiceType from "@/view/components/Manage-Service-Type.vue";

/* import ObjectPath from "object-path"; */

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default: null,
		},
	},
	watch: {
		serviceDialog(param) {
			if (param) {
				this.init();
			}
		},
		serviceArray(param) {
			if (param) {
				this.service = {
					from: param.actual_start_date,
					to: param.actual_end_date,
					cost: param.cost,
					expected_completion: param.expected_end_date,
					inventories: [],
					attachments: [],
					service_type: param.service_type,
					performed_by: param.service_by,
					location_by: param.location_by,
					indefinitely: param.indefinitely,
					vendor: param.supplier,
					member: param.member,
					description: param.description,
				};
				this.serviceUuid = param.uuid;
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			serviceTypeList: [],
			vendorList: [],
			memberList: [],
			inventoryList: [],
			manageServiceTypeDialog: false,
			inventory: {},
			serviceUuid: null,
			service: {
				from: null,
				to: null,
				cost: null,
				expected_completion: null,
				inventories: [],
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				service_type: null,
				performed_by: 1,
				location_by: 1,
				indefinitely: 0,
				vendor: 0,
				member: 0,
				description: null,
			},

			radioItems: [
				{
					label: "Make Item unavailable (Item will be put in maintenance state)",
					value: 1,
				},
				{
					label: "Create a service record. Do not make item unavailable",
					value: 2,
				},
			],
			performedItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			performedPlaceItems: [
				{
					label: "Onsite",
					value: 1,
				},
				{
					label: "Workshop",
					value: 2,
				},
			],
			/*   performedPlaceItems: [
			{
					label: "Onsite",
					value: 1,
				},
				{
					label: "Workshop",
					value: 2,
				},

			] */
		};
	},
	components: {
		Dialog,
		//	UploadFile,
		RadioInput,
		DateTimePicker,
		ManageServiceType,
		//QuantityInput,
		PriceInput,
		TextAreaInput,
		AutoCompleteInput,
		//TextInput
	},
	methods: {
		/* addMore() {
			this.service.attachment.push({
				file: null,
				url: null,
				name: null
			});
		},
		removeFile(index) {
			this.service.attachment.splice(index, 1);
			if (this.service.attachment.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}
          
			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.service.attachment[index].url = ObjectPath.get(response, '0.path', null);
					this.service.attachment[index].name = ObjectPath.get(response, '0.name', null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		init() {
			this.service = new Object({
				type: 1,
				from: null,
				to: null,
				cost: null,
				expected_completion: null,
				inventories: [],
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				service_type: null,
				performed_by: 1,
				location_by: 1,
				indefinitely: 0,
				vendor: 0,
				member: 0,
				description: null,
			});
		},

		selectInventory() {
			const inventory = cloneDeep(this.inventory);
			this.inventory = new Object({});
			let preInventory = findIndex(this.service.inventories, { id: inventory.value });
			if (preInventory != -1) {
				this.service.inventories[preInventory].quantity =
					toSafeInteger(this.service.inventories[preInventory].quantity) + 1;
			} else {
				this.service.inventories.push({
					id: inventory.value,
					uuid: inventory.uuid,
					name: inventory.text,
					quantity: 1,
				});
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/damage-service-start`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.put(`${this.endpoint}/service-start/${this.serviceUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		removeInventory(index) {
			let inventoryArr = this.service.inventories;
			inventoryArr.splice(index, 1);
			this.service.inventories = inventoryArr;
		},
		getServiceTypes(data) {
			this.serviceTypeList = data;
		},
	},
	mounted() {
		this.serviceTypeList = this.localDB("service_types", []);
		this.vendorList = this.localDB("suppliers", []);
		this.memberList = this.localDB("members", []);
		this.inventoryList = this.localDB("inventories", []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
